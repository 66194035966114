import React from 'react';
import ListingImageGallery from './ListingImageGallery/ListingImageGallery';

import css from './ListingPage.module.css';

const SectionGallery = props => {
  const { listing, variantPrefix } = props;

  const images = listing.images;
  const selectedPhotoId = listing.attributes?.publicData?.selectedPhoto
    ? listing.attributes.publicData.selectedPhoto
    : null;

  //get the index of selection
  const displayPicIndx = selectedPhotoId
    ? images.map(img => img.id.uuid).indexOf(selectedPhotoId)
    : null;

  //get favorite photo as an object
  const selectedPhotoObj = displayPicIndx
    ? images.find(image => {
        return image.id.uuid === selectedPhotoId;
      })
    : null;

  //cut from its position in array and move to start
  const orderedImages = selectedPhotoObj
    ? images.toSpliced(displayPicIndx, 1)
    : null;

  if (orderedImages) {
    orderedImages.unshift(selectedPhotoObj);
  }

  const imageVariants = [
    'scaled-small',
    'scaled-medium',
    'scaled-large',
    'scaled-xlarge',
  ];
  const thumbnailVariants = [
    variantPrefix,
    `${variantPrefix}-2x`,
    `${variantPrefix}-4x`,
  ];
  return (
    <section className={css.productGallery} data-testid="carousel">
      <ListingImageGallery
        images={orderedImages ? orderedImages : images}
        imageVariants={imageVariants}
        thumbnailVariants={thumbnailVariants}
      />
    </section>
  );
};

export default SectionGallery;
